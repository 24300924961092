import React from "react";
import { useTranslation } from "react-i18next";

import { getUUID } from "../../../tools/getUUID";

// mui
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

// icons
import { ReactComponent as ArchiveIcon } from "../../../assets/images/components/Notifications/message-type/archive.svg";
import { ReactComponent as EndsStuffIcon } from "../../../assets/images/components/Notifications/message-type/ends-stuff.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/components/Notifications/message-type/info.svg";
import { ReactComponent as LimitIcon } from "../../../assets/images/components/Notifications/message-type/limit.svg";
import { ReactComponent as NewOrderIcon } from "../../../assets/images/components/Notifications/message-type/new_order.svg";
import { ReactComponent as PaidStuffIcon } from "../../../assets/images/components/Notifications/message-type/paid-stuff.svg";

const SelectFilter = (props) => {
  const { t } = useTranslation();
  const { notificationFilter, setNotificationFilter, loading } = props;

  // 1	"Information"
  // 2	"OrderPaid"
  // 3	"ProductRunningOut"
  // 4	"ProductLimit"
  // 5	"OrderNew"

  const notification_filters = [
    { label: t("Все"), id: getUUID(), value: 0 },
    {
      label: t("Нове замовлення"),
      id: getUUID(),
      value: 5,
      icon: <NewOrderIcon />,
    },
    {
      label: t("Оплачений товар"),
      id: getUUID(),
      value: 2,
      icon: <PaidStuffIcon />,
    },
    {
      label: t("Товар заканчивается"),
      id: getUUID(),
      value: 3,
      icon: <EndsStuffIcon />,
    },
    { label: t("Ліміт товару"), id: getUUID(), value: 4, icon: <LimitIcon /> },
    { label: t("Інформаційні"), id: getUUID(), value: 1, icon: <InfoIcon /> },
    {
      label: t("Архив"),
      id: getUUID(),
      value: "archive",
      icon: <ArchiveIcon />,
    },
  ];

  return (
    <>
      <div className="notifications-filters">
        <div className="notifications-filters_item">
          {notification_filters.map((el) => (
            <p
              key={el.id}
              onClick={() =>
                loading ? () => {} : setNotificationFilter(el.value)
              }
              className={
                notificationFilter === el.value ? "active_border" : "dis_border"
              }
            >
              {el.icon && el.icon}
              {el.label}
            </p>
          ))}
        </div>
      </div>
      <div className="notifications-filters-small">
        <FormControl variant="filled">
          <Select
            value={notificationFilter}
            onChange={(e) =>
              loading
                ? () => {}
                : setNotificationFilter(
                    e.target.value === "today" ? null : e.target.value,
                  )
            }
          >
            {notification_filters.map((el) => (
              <MenuItem
                value={el.value}
                key={el.id}
                style={{ display: "flex", alignItems: "center" }}
              >
                {el.icon && el.icon}
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default SelectFilter;
