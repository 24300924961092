import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import "./app.scss";
import Wrapper from "./components/Wrapper/Wrapper";

import AuthorizedRoutes from "./components/routes/AuthorizedRoutes";
import UnAuthorizedRoutes from "./components/routes/UnAuthorizedRoutes";
import Alert from "./components/common/alerts/Alert";
import Prompt from "./components/common/prompt/Prompt";

function App(props) {
  const isAuth = !!localStorage.getItem("token");
  const isAlertShown = useSelector((state) => state.alert.isAlertShown);
  const isPromptShown = useSelector((state) => state.alert.isPromptShown);

  if (window.innerWidth < 450) {
    return (
      <div className="disabled_phone_block">
        <div>
          <p>
            {
              "Веб адмін-панель UTake працює тільки на девайсах з розширенням пікселів завширшки > 450"
            }
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      {props.loading && <></>}
      {!props.loading && (
        <>
          {isAuth && (
            <Wrapper {...props} status={props.status} userId={props.userId}>
              <AuthorizedRoutes />
            </Wrapper>
          )}
          {!isAuth && <UnAuthorizedRoutes />}
        </>
      )}
      {isAlertShown && <Alert />}
      {isPromptShown && <Prompt />}
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedMerchant: state.admin.selectedMerchant,
  info: state.auth.info,
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
