import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

import Spinner from "../../../components/UI/Spinner/Spinner";
import Error from "../../../components/UI/Errors/CustomError/CustomError";

//mui
import { makeStyles } from "@material-ui/core/styles";
import FilledInput from "@material-ui/core/FilledInput";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useParams } from "react-router-dom";
import "../auth.scss";
import AuthService from "../../../api/services/AuthService";
import { joinPosterConfirmUrl, joinposterId } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  firstInput: {
    width: "100%",
    marginTop: "50px",
    borderRadius: "0px !important",
    "& .MuiFilledInput-root": {
      backgroundColor: "#f2f3f3",
      "&:hover": {
        backgroundColor: "#d6ebef",
      },
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "2px solid #9978E2",
    },
  },
  secondInput: {
    width: "100%",
    borderRadius: "0px",
    marginTop: "50px",
    "& .MuiInputLabel-formControl": {
      left: 10,
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(0, 10px) scale(0.75)",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#f2f3f3",
      "&:hover": {
        backgroundColor: "#d6ebef",
      },
    },
    "& label": {
      zIndex: 100,
      "&:hover": {
        "& .MuiInputBase-root": {
          backgroundColor: "#d6ebef",
        },
      },
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "2px solid #9978E2",
    },
  },
  borderRadius: {
    borderRadius: "0px",
    paddingLeft: "10px",
  },
}));

const SamoAuth = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeLanguage = (name) => {
    i18n.changeLanguage(name);
    setLang(name);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onLogIn = () => {
    setLoading(true);
    setError(null);

    AuthService.login(login, password)
      .then((res) => {
        setError(null);
        setLoading(false);
        const merchantId = res.data.merchantId;
        const shopIds = [...res.data.shopIds];
        const token = res.data.jwtToken;
        const id = res.data.id;
        const roleId = res.data.roleId;
        const firstName = res.data.firstName;
        const middleName = res.data.middleName;
        const lastName = res.data.lastName;
        const roleName = res.data.roleName;
        const title = res.data.title;

        /*TEST*/
        /*if (![-1, 1].includes(res.data.roleId)) {
          $api
            .get(`/Merchants/${merchantId}/shops/${shopId}`)
            .then((result) => {
              props.login({
                token,
                expiresIn: res.data.jwtTokenExpiry,
                info: {
                  id,
                  merchantId,
                  roleId,
                  shopId,
                  firstName,
                  middleName,
                  lastName,
                  roleName,
                  title,
                  shopName: result.data.name,
                  shopCity: result.data.city,
                  shopStreet: result.data.street,
                  buildingNumber: result.data.buildingNumber,
                  logo: result.data.logo,
                },
              });
            })
            .catch(() => setError(t("auth.error")));
        } else {*/
        props.login({
          token,
          expiresIn: res.data.jwtTokenExpiry,
          info: {
            id,
            merchantId,
            roleId,
            shopIds,
            firstName,
            middleName,
            lastName,
            roleName,
            title,
          },
        });
        /*}*/
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response ? err.response.data.message : t("auth.error"));
      });
  };

  const formIsValid = login.trim() !== "" && password.trim() !== "";

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (login.trim() !== "" && password.trim() !== "") onLogIn();
      }
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [login, password]);

  return (
    <div className="auth_main">
      <div className="auth_main_top">
        <li
          className={lang === "ua" ? "active" : null}
          onClick={() => handleChangeLanguage("ua")}
        >
          Українська
        </li>
        <li
          className={lang === "ru" ? "active" : null}
          onClick={() => handleChangeLanguage("ru")}
        >
          Русский
        </li>
      </div>
      {error ? (
        <Error title={error} />
      ) : (
        <div className="auth_main__area">
          <div className="auth_main__area_header">
            <p>{t("auth.login")}</p>
          </div>
          <TextField
            onChange={(e) => setLogin(e.target.value)}
            value={login}
            label={t("auth.placeholderLogin")}
            variant="filled"
            className={classes.firstInput}
          />
          <FormControl className={classes.secondInput}>
            <InputLabel htmlFor="filled-adornment-password">
              {t("auth.placeholderPassword")}
            </InputLabel>
            <FilledInput
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className={classes.borderRadius}
              id="filled-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {!loading && (
            <div>
              <button
                onClick={onLogIn}
                type="submit"
                disabled={!formIsValid}
                className={
                  formIsValid
                    ? "auth_main__area_button"
                    : "auth_main__area_disabled_button"
                }
              >
                {t("auth.login")}
              </button>
              <p className="parag">{t("auth.flag")}</p>
            </div>
          )}
          {loading && (
            <div
              style={{
                width: "100%",
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(actions.loginSuccess(data)),
  logout: () => dispatch(actions.logout()),
});

export default connect(null, mapDispatchToProps)(SamoAuth);
