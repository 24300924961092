import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// functions
import {
  onChangeInput,
  onChangeSize,
} from "../../utils/createProdFunctions/createProdFunctions";

// components
import Spinner from "../../../../../../../components/UI/Spinner/Spinner";
import Error from "../../../../../../../components/UI/Errors/ServerError/ServerError";
import CustomError from "../../../../../../../components/UI/Errors/CustomError/CustomError";
import SelectCat from "../SelectCat/SelectCat";
import ProductsAPI from "../../../../../../../models/storage/products/products";

// svg
import basket from "../../../../../../../assets/images/pages/Products/ProdForm/basket.svg";
import close from "../../../../../../../assets/images/pages/Products/ProdForm/close.svg";
import done from "../../../../../../../assets/images/pages/Products/ProdForm/done.svg";
import cancel from "../../../../../../../assets/images/pages/Products/ProdForm/cancel.svg";

const CreateProd = (props) => {
  const { merchantId, shopId, cats: categories } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formError, setFormError] = useState(null);

  const initialDescription = ContentState.createFromText(t('createProd.descr'));

  const [description, setDescription] = useState(
    EditorState.createWithContent(initialDescription),
  );
  const [prodType, setProdType] = useState("weight");

  const [form, setForm] = useState([
    {
      value: "",
      rules: {
        required: true,
        minLength: 2,
        maxlength: 50,
      },
      error: t("prodErrors.0"),
      name: "name",
      valid: false,
      touched: false,
    },
    {
      value: "",
      rules: {
        required: true,
      },
      error: t("prodErrors.1"),
      name: "category",
      valid: false,
      touched: false,
    },
    {
      value: "",
      rules: {
        required: true,
        minLength: 1,
        maxlength: 2000,
      },
      error: t("prodErrors.2"),
      name: "url",
      valid: false,
      touched: false,
    },
    {
      value: "g",
      rules: {
        required: true,
      },
      error: t("prodErrors.3"),
      name: "type",
      valid: true,
      touched: false,
    },
    {
      value: "",
      rules: {
        required: true,
        minLength: 1,
        maxlength: 6,
      },
      error: t("prodErrors.4"),
      name: "count",
      valid: false,
      touched: false,
    },
    {
      value: "",
      rules: {
        required: true,
        minLength: 1,
        maxlength: 6,
      },
      error: t("prodErrors.5"),
      name: "price",
      valid: false,
      touched: false,
    },
  ]);
  const [size, setSize] = useState("");

  const onCreateProd = async () => {
    setLoading(true);
    setError(false);
    const products = new ProductsAPI(merchantId, shopId);
    try {
      const result = await products.onCreateProd({
        bc: "hz",
        t: form[0].value,
        d: draftToHtml(convertToRaw(description.getCurrentContent())),
        p: form[5].value,
        w: form[3].value === "ml" ? 0 : size,
        v: form[3].value === "g" ? 0 : size,
        img: form[2].value,
        q: form[4].value,
        bl: prodType === "weight" ? true : false,
        clst: [form[1].value],
      });
      props.history.push({
        pathname: "/main/storage/products",
        state: {
          successCreated: true,
          name: form[0].value,
        },
      });
    } catch (e) {
      if (e?.response?.data?.message) {
        setError(e.response.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const changeInputData = {
    form,
    setForm,
    setFormIsValid,
    setFormError,
  };

  if (error === true) return <Error />;

  if (typeof error === "string") return <CustomError title={error} />;

  if (loading)
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );

  return (
    <div className="create-prod-container">
      <div className="close-div" onClick={props.onClose}>
        <p>{t("createProd.closeLabel")}</p>
        <img src={close} alt="" />
      </div>
      <h2>
        <img src={basket} alt="" />
        <p>{t("createProd.title")}</p>
      </h2>

      <div className="content">
        {/* top */}
        <div className="content__top">
          <div className="content__item">
            <label
              htmlFor="name"
              className={formError === form[0].error ? "error" : null}
            >
              {t("createProd.name")}
            </label>
            <input
              type="text"
              id="name"
              value={form[0].value}
              onChange={(e) => onChangeInput(e, "name", changeInputData)}
              className={
                formError === form[0].error
                  ? "max-content error"
                  : "max-content"
              }
              placeholder={t("createProd.placeholders.enterName")}
            />
          </div>
          <div className="content__item">
            <label
              htmlFor="cat"
              className={formError === form[0].error ? "error" : null}
            >
              {t("createProd.cat")}
            </label>
            <SelectCat
              cats={categories}
              value={form[1].value}
              change={(e) => onChangeInput(e, "category", changeInputData)}
              type={"add-prod-type"}
            />
          </div>
          <div className="content__item">
            <label
              htmlFor="url"
              className={formError === form[2].error ? "error" : null}
            >
              {t("createProd.url")}
            </label>
            <input
              type="text"
              id="url"
              value={form[2].value}
              onChange={(e) => onChangeInput(e, "url", changeInputData)}
              className={
                formError === form[2].error
                  ? "max-content error"
                  : "max-content"
              }
              placeholder={t("createProd.placeholders.enterLink")}
            />
          </div>
          <div className="content__bottom">
            <div className="column"  style={{minHeight: '264px'}}>
              <div
                className="content__item"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  minHeight: '100px',
                  minWidth: '480px'
                }}
              >
                <label htmlFor="type">{t("createProd.productType")}: </label>
                <div className="active-select">
                  <div
                    onClick={() => setProdType("weight")}
                    className={
                      prodType === "weight"
                        ? "active-select-item-active"
                        : "active-select-item"
                    }
                  >
                    <p >{t("createProd.productTypes.weight")}</p>
                  </div>
                  <div
                    onClick={() => setProdType("sht")}
                    className={
                      prodType === "sht"
                        ? "active-select-item-active"
                        : "active-select-item"
                    }
                  >
                    <p>{t("createProd.productTypes.piece")}</p>
                  </div>
                </div>
              </div>
              {prodType === "weight" && (
                <>
                  <div
                    className="content__item"
                    style={{ display: 'block' }}
                  >
                    <div style={{width: '210px', display: 'flex', justifyContent: 'space-between'}}>
                    <label
                      htmlFor="price"
                      className={formError === form[5].error ? "error" : null}
                    >
                      {t("createProd.price")}
                    </label>
                    <input
                      value={form[5].value.toString()}
                      onChange={(e) =>
                        onChangeInput(e, "price", {
                          isNumber: true,
                          ...changeInputData
                        })
                      }
                      type="number"
                      id="price"
                      className={
                        formError === form[5].error ? "error small" : "small"
                      }
                    />
                    </div>
                  </div>
                  <div className="content__item"
                       style={{ alignItems: "center", justifyContent: 'space-between' }}>
                    <div style={{width: '210px', display: 'flex', justifyContent: 'space-between'}}>
                    <label>{"за:"}</label>
                    <input
                      value={size.toString()}
                      onChange={(e) =>
                        onChangeSize(e.target.value, { setSize })
                      }
                      type="number"
                      id="mlsz"
                      style={{ marginLeft: "10px" }}
                      className="small"
                    />
                    </div>
                    <div className="active-select">
                      <div
                        onClick={() =>
                          onChangeInput(
                            { target: { value: "g" } },
                            "type",
                            changeInputData
                          )
                        }
                        className={
                          form[3].value === "g"
                            ? "active-select-item-active"
                            : "active-select-item"
                        }
                      >
                        <p>{t("createProd.measUnits.gr")}</p>
                      </div>
                      <div
                        onClick={() =>
                          onChangeInput(
                            { target: { value: "ml" } },
                            "type",
                            changeInputData
                          )
                        }
                        className={
                          form[3].value === "ml"
                            ? "active-select-item-active"
                            : "active-select-item"
                        }
                      >
                        <p>{t("createProd.measUnits.ml")}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {prodType === "sht" && (
                <>
                  <div
                    className="content__item"
                    style={{ alignItems: "center", justifyContent: 'space-between' }}
                  >
                    <div style={{width: '250px', display: 'flex', justifyContent: 'space-between'}}>
                    <label htmlFor="mlsz">{t("createProd.weightAndVolume")}</label>
                    <input
                      value={size.toString()}
                      onChange={(e) =>
                        onChangeSize(e.target.value, { setSize })
                      }
                      type="number"
                      id="mlsz"
                      className="small"
                    />
                    </div>
                    <div className="active-select">
                      <div
                        onClick={() =>
                          onChangeInput(
                            { target: { value: "g" } },
                            "type",
                            changeInputData
                          )
                        }
                        className={
                          form[3].value === "g"
                            ? "active-select-item-active"
                            : "active-select-item"
                        }
                      >
                        <p>{t("createProd.measUnits.gr")}</p>
                      </div>
                      <div
                        onClick={() =>
                          onChangeInput(
                            { target: { value: "ml" } },
                            "type",
                            changeInputData
                          )
                        }
                        className={
                          form[3].value === "ml"
                            ? "active-select-item-active"
                            : "active-select-item"
                        }
                      >
                        <p>{t("createProd.measUnits.ml")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="content__item">
                    <label
                      htmlFor="price"
                      className={formError === form[5].error ? "error" : null}
                      style={{minWidth: '40px !important'}}
                    >
                      {t("createProd.price")}
                    </label>
                    <input
                      value={form[5].value.toString()}
                      onChange={(e) =>
                        onChangeInput(e, "price", {
                          isNumber: true,
                          ...changeInputData,
                        })
                      }
                      type="number"
                      id="price"
                      className={formError === form[5].error ? "error" : null}
                    />
                  </div>
                </>
              )}
              <div className="content__item">
                <label
                  htmlFor="count"
                  className={formError === form[4].error ? "error" : null}
                >
                  {`${t('createProd.inStock')} (${
                    prodType === "sht"
                      ? t("createProd.measUnits.piece")
                      : form[3].value === "ml"
                        ? t("createProd.measUnits.ml")
                        : t("createProd.measUnits.gr")
                  }) :`}
                </label>
                <input
                  type="number"
                  id="count"
                  value={form[4].value.toString()}
                  onChange={(e) =>
                    onChangeInput(e, "count", {
                      isNumber: true,
                      ...changeInputData,
                    })
                  }
                  className={formError === form[4].error ? "error" : null}
                />
              </div>
            </div>
            <div className="column">
              <div className="content__item editor">
                <Editor
                  editorState={description}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={(el) => setDescription(el)}
                />
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="bottom">
            <p className="error">{formError}</p>
            <div className="buttons">
              <button
                className={
                  !formIsValid ||
                  ((form[3].value === "sht" || form[3].value === "ml") &&
                    size === "")
                    ? "disabled"
                    : "confirm"
                }
                onClick={
                  !formIsValid ||
                  ((form[3].value === "sht" || form[3].value === "ml") &&
                    size === "")
                    ? () => {}
                    : onCreateProd
                }
              >
                <img src={done} alt="" />
                <p>{t("createProd.button1")}</p>
              </button>
              <button onClick={props.onClose} className="cancel">
                <img src={cancel} alt="" />
                <p>{t("createProd.button2")}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProd;
