import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoryAPI from "../../models/storage/categories/categories";
import ProductsAPI from "../../models/storage/products/products";

import SelectCat from "../../pages/Storage/PageStorage/components/Products/components/SelectCat/SelectCat";
import ProductItem from "./ProductsForModalItem/ProductsForModalItem";
import TextField from "@material-ui/core/TextField";
import ServerError from "../UI/Errors/ServerError/ServerError";
import Spinner from "../UI/Spinner/Spinner";
import close from "../../assets/images/pages/Products/ProdForm/close.svg";
import Pagination from "rc-pagination";

const listingArr = (arr, i) => {
  let result = [...arr];
  result = result.map((el) => [
    { el, level: i },
    el.children && el.children.map((el) => listingArr([el], i + 1)),
  ]);
  return result;
};

const ProductsForModal = (props) => {
  const { merchantId, shopId, modal } = props;
  const { t } = useTranslation();

  const [allCats, setAllCats] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [searchCat, setSearchCat] = useState("");
  const [searchMode, setSearchMode] = useState(false);

  // pagination state
  //const [pageSize, setPageSize] = useState(3);
  const pageSize = 20;
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [productsTotal, setProductsTotal] = useState(undefined);

  useEffect(async () => {
    setLoading(true);
    setError(false);
    const category = new CategoryAPI(merchantId, shopId);
    const products = new ProductsAPI(merchantId, shopId);
    try {
      const result = await category.getAllProd();
      const resultProd = await products.getAllProd(pageSize, 0);
      let newAllCats = result.data.results.filter((el) => !el.parentId);
      newAllCats = listingArr(newAllCats, 0);
      newAllCats = newAllCats.map((el) => el.flat(Infinity));
      setAllCats(newAllCats);
      setProducts(resultProd.data.results);
      setProductsTotal(resultProd.data.total);
    } catch (e) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  }, []);

  const updateProducts = async (newPage) => {
    const products = new ProductsAPI(merchantId, shopId);
    try {
      setLoading(true);
      setError(false);
      const resultProd = await products.getAllProd(
        pageSize,
        (pageSize * newPage) - pageSize, searchName, searchCat
      );
      setProducts(resultProd.data.results);
      setProductsTotal(resultProd.data.total);
    } catch (e) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  };

  const onSearch = async () => {
    if (searchName === "" && searchCat === "") return;
    const products = new ProductsAPI(merchantId, shopId);
    try {
      const result = await products.search(searchName, searchCat, pageSize,
        0);
      setProducts(result.data.results);
      setProductsTotal(result.data.total);
      setCurrentPageNum(1);
      setSearchMode(true);
    } catch (e) {
      setError(true);
    }
  };

  const onCancelSearch = async () => {
    setError(false);
    setLoading(true);
    setSearchMode(false);
    setSearchName("");
    setSearchCat("");
    const products = new ProductsAPI(merchantId, shopId);
    try {
      const resultProd = await products.getAllProd(pageSize, 0);
      setProducts(resultProd.data.results);
      setProductsTotal(resultProd.data.total);
      setCurrentPageNum(1);
      //setPageSize(pageSize)
    } catch (e) {
      setError(true);
    }
    finally {
      setLoading(false);
    }
  };

  const w = window.innerWidth;

  const PaginationChange = async (page, size) => {
    setCurrentPageNum(page);
    await updateProducts(page);
    //setPageSize(size)
  }

  const PrevNextArrow = (currentPageNum, type, originalElement) => {
    if (type === 'prev') {
      return <div>«</div>;
    }
    if (type === 'next') {
      return <div>»</div>;
    }
    return originalElement;
  }

  return (
    <div className="products-container">
      <div className="close-div" onClick={props.onClose}>
        <p>{t("createProd.closeLabel")}</p>
        <img src={close} alt="" />
      </div>
      {error && <ServerError />}
      {!error && (
        <>
        <div className="products-container_header">
          <h2>{t("prod.search.selectTitle")}</h2>
        </div>
        <div className="products-container_header_bottom">
          <TextField
            style={{ marginRight: "10px", marginTop: "10px" }}
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            label={t("prod.search.name")}
            type="search"
            variant="outlined"
          />
          <div style={{ marginRight: "10px", marginTop: "10px" }}>
            <SelectCat
              cats={allCats}
              value={searchCat}
              change={(e) => setSearchCat(e.target.value)}
            />
          </div>
          <button onClick={onSearch} style={{ marginRight: "10px" }}>
            {t("prod.search.search")}
          </button>
          {searchMode && (
            <button className="cancel" onClick={onCancelSearch}>
              {t("prod.search.cancel")}
            </button>
          )}
        </div>
        {loading && (
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        )}
        {!error && !loading && (
          <ul className="products-container_content-search">
            <li>
              <div className="pd-name">{t("prod.table.1")}</div>
              <div className="pd-image">{t("prod.table.2")}</div>
              <div className="pd-cat">{t("prod.table.3")}</div>
              <div className="pd-price" style={{ width: "calc(13% + 40px)" }}>
                {t("prod.table.4")}
              </div>
              <div className="pd-count">{t("prod.table.6")}</div>
            </li>
            {products.map((el, index) => (
              <ProductItem
                key={index}
                product={el}
                cats={allCats}
                modal={modal}
                onAddModalProd={props.onAdd}
              />
            ))}
          </ul>
        )}
        <div className="products-container-pagination">
          <Pagination
            className="pagination-data"
            showTotal={(total, range) => <div>Показано:&nbsp;<span style={{ fontWeight: 500 }}>{range[0]}</span>-<span
              style={{ fontWeight: 500 }}>{range[1]}</span>&nbsp;{t("global.from")}&nbsp;<span
              style={{ fontWeight: 500 }}>{total}</span></div>}
            onChange={PaginationChange}
            total={productsTotal}
            current={currentPageNum}
            pageSize={pageSize}
            showSizeChanger={true}
            itemRender={PrevNextArrow}
          />
        </div>
        </>
        )}
        </div>
      );
      };

      export default ProductsForModal;
