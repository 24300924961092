import React from "react";

import "./footer.scss";

//img
import oschad from "../../../assets/images/wrapper/footer/oschad_text_blue.svg";

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="footer_logos">
        <img src={oschad} alt="logo" />
        {/*<img src={visa} alt="logo" className="pl" />*/}
      </div>
      <p>{`© UkrPay, ${new Date().getFullYear()}`}</p>
    </footer>
  );
};

export default Footer;
