import React from "react";
import { useTranslation } from "react-i18next";

// icon
import close from "../../../../assets/images/pages/Products/ProdForm/close.svg";
import { ReactComponent as TrashGrayDeleteIcon } from "../../../../assets/images/components/Notifications/trash-gray-delete.svg";
import { ReactComponent as TrashWhiteIcon } from "../../../../assets/images/components/Notifications/trash-white.svg";
import { ReactComponent as CloseBlueIcon } from "../../../../assets/images/components/Notifications/close-blue.svg";

import "../notificationsModal.scss";

export const DeleteSingle = (props) => {
  const { t } = useTranslation();
  const { onClose, onDeleteSingle } = props;

  return (
    <div className="modal-window-delete-notifications-modal">
      <div className="close-div" onClick={onClose}>
        <p>{t("createProd.closeLabel")}</p>
        <img src={close} alt="" />
      </div>
      <div className="title-block">
        <TrashGrayDeleteIcon />
        <h2>ВИДАЛЕННЯ ПОВІДОМЛЕНЬ</h2>
      </div>
      <p>1 повідомлення буде видалено безповоротно.</p>
      <p>Ви впевнені, що хочете продовжити?</p>
      <div className="buttons">
        <button onClick={onDeleteSingle} className="action">
          <TrashWhiteIcon />
          <span>Видалити</span>
        </button>
        <button onClick={onClose} className="cancel">
          <CloseBlueIcon />
          <span>Скасувати</span>
        </button>
      </div>
    </div>
  );
};
