import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import AuthModal from "../../components/UI/AuthModal/AuthModal";
import { useParams } from "react-router-dom";
import "./auth.scss";

//images
import oshcad from "../../assets/images/wrapper/footer/oschad_text_white.svg";

import logo from "../../assets/images/components/SignUp/logo.svg";
import message from "../../assets/images/components/SignUp/message_white.svg";
import JoinposterConfirm from "./JoinposterConfirm/JoinposterConfirm";
import InstructionPage from "./InstructionPage/InstructionPage";
import { authUrls } from "../../dataUI/common/commonConsts";
import JoinPosterRegistration from "./JoinPosterRegistration/JoinPosterRegistration";
import SamoAuth from "./SamoAuth/SamoAuth";
import IframePosterConfirm from "./IframePosterConfirm/IframePosterConfirm";
import IframePosterMerchant from "./IframePosterMerchant/IframePosterMerchant";
import JoinPosterIframe from "./JoinPosterIframe";

const Auth = (props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const { authPath } = useParams();

  return (
    <div className="auth">
      <div style={{width: 0, height:0, opacity: 0}}>updated 18:15 13.06.2024</div>
      <AuthModal show={showModal} onClose={() => setShowModal(false)} />
      <div
        className="auth_label"
        style={
          authPath !== authUrls.SAMO_AUTH
            ? { position: "fixed", height: "100vh" }
            : {}
        }
      >
        <div className="auth_label_top">
          <img alt="" src={logo} className="auth_label_top_left" />
          <div>
            <p className="first">{t("auth.welcome")}</p>
            <p className="second">{"Samo!"}</p>
          </div>
        </div>
        <div className="auth_label_bottom">
          {authPath === authUrls.SAMO_AUTH && (
            <div className="auth_label_bottom_top">
              <div className="logos">
                <img src={oshcad} alt="logo"  />
                {/*<img src={visa} alt="logo" className="pl" />*/}
              </div>
            </div>
          )}

          {authPath !== authUrls.SAMO_AUTH && (
            <div className="instructionPage_contacts">
              <div className="contactBox">
                <div className="contactDescription">
                  {t("instructionPage.site")}
                </div>
                <a
                  className="contactValue"
                  href="http://ukrpay.net/"
                  target="_blank"
                >
                  http://ukrpay.net/
                </a>
              </div>
              <div className="contactBox">
                <div className="contactDescription">
                  {t("instructionPage.email")}
                </div>
                <div className="contactValue">samo@ukrpay.net</div>
              </div>
              {/*<div className="contactBox">
                <div className="contactDescription">
                  {t("instructionPage.address")}
                </div>
                <div className="contactValue">Київ, вул. Джона Маккейна, 7</div>
              </div>*/}
            </div>
          )}

          <div className="stripe" />
          <div
            className="auth_label_bottom_bottom"
            onClick={() => setShowModal(true)}
          >
            <img src={message} alt="" />
            <p>{t("auth.support.title")}</p>
          </div>
        </div>
      </div>

      {authPath === authUrls.SAMO_AUTH && <SamoAuth {...props} />}
      {authPath === authUrls.INSTRUCTION && <InstructionPage />}
      {authPath === authUrls.REG && <JoinPosterRegistration />}
      {authPath === authUrls.REG_CONFIRM && <JoinposterConfirm />}

      {authPath === authUrls.IFRAME ||
        (authPath.includes(authUrls.IFRAME) && <JoinPosterIframe />)}

      {/*{authPath === authUrls.REG_CONFIRM && (
        <JoinposterConfirm  />
      )}
      {authPath === authUrls.REG_STEP2 || authPath.includes(authUrls.REG_STEP2) && (
        <IframePosterMerchant />
      )}*/}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(actions.loginSuccess(data)),
  logout: () => dispatch(actions.logout()),
});

export default connect(null, mapDispatchToProps)(Auth);
