export const ua = {
  translation: {
    // global
    global: {
      edit: "Редагувати",
      delete: "Видалити",
      success: "Реєстрація виконана успішно",
      regDescription:
        'Для завершення реєстрації перейдіть у розділ меню "Застосунки" - оберіть "SAMO" та заповніть усі поля форми.',
      error: "Виникла помилка.",

      modalText: {
        shops: {
          successfullyChanged: "Ви успішно змінили дані магазину!",
        },
        merchant: {
          successfullyChanged: "Ви успішно змінили Торгову Точку!",
        },
      },

      errors: {
        required: "Дане поле не може бути пустим",
      },
      from: 'із'
    },
    common: {
      roles: {
        operator: "Оператор ТП",
        cashier: "Касир ТП",
        admin: "Адміністратор",
      },
    },
    joinposter: {
      merchantForm: {
        name: "* Назва закладу",
        description: "* Опис закладу",
        logo: "* Логотип закладу",
        category_id: "* Категорії мерчанта",
        regionId: "* Регіон торгової точки",
        categories: {
          1: "АЗС",
          2: "Ресторани",
          3: "Дитячі товари",
          4: "Продукти",
        },
        changeLogo: "Змінити логотип",
        addLogo: "Додати логотип",
      },
      storeForm: {
        name: "* Назва торгової точки",
        address: "* Адреса торгової точки",
        schedule: "* Графік роботи",
        paymentMerchantId: "* MERCHANT_ID",
        paymentTerminalId: "* TERMINAL_ID",
        payment_id_error: "Обмеження для даного поля - лише цифри, 8 знаків",
        photo: "* Фото торгової точки",
        changePhoto: "Змінити фото",
        addPhoto: "Додати фото",
      },
    },
    // auth window
    auth: {
      support: {
        title: "Служба підтримки клієнтів Samo",
        phone: "Телефон гарячої лінії",
        close: "Закрити",
      },
      login: "Ввійти",
      posterAuth: "Авторизуватися через joinposter",
      storeImport: "Імпорт торгових точок",
      storeImport_button: "Розпочати імпорт",
      syncImport_button: "Синхронізувати дані",
      welcome: "Вітаємо в",
      error: "Серверна помилка...",
      placeholderLogin: "Ваш логін*",
      placeholderPassword: "Пароль*",
      flag: "* - усі поля обов‘язкові для заповнення",
    },
    // instructionPage
    instructionPage: {
      site: "Відвідати сайт розробника:",
      email: "Електронна пошта:",
      address: "Наша адреса:",
    },

    // header
    header: {
      merchant: "Торгівля",
      admin: "Адміністрування",
      role: {
        1: {
          1: "Вікно адміна",
        },
        "-1": {
          1: "Вікно Суперюзера",
        },
      },
      order: "Номер замовлення",
      dropDown: {
        1: "Особистий кабінет",
        2: "Вийти",
        3: "Кабінет",
      },
      bottom: {
        history: {
          title1: "Історія замовлень: ",
          title2: "Історія: ",
          1: "Сьогодні",
          2: "Вчора",
          3: "За тиждень",
          4: "За місяць",
          5: "За рік",
        },
        links: {
          1: "Асортимент",
          2: "замовлення",
        },
      },
    },

    // shops list
    shopsList: {
      //title: 'Торгівельні Точки',
      tabName1: "Торгівельні Точки",
      tabName2: "Персонал",
      subtitle1: "Адреса / час роботи /",
      subtitle2: "контакт продавця",
      createNew: "Додати торгівельну точку",
      searchPlaceHolder1: "Назва торгової точки, місто, вулиця",
      searchPlaceHolder2: "ПІБ, посада",
    },
    // personal
    personal: {
      title: "Персонал торгівельної точки",
      subtitle1: "ПІБ/ ",
      subtitle2: "Посада /",
      subtitle3: "Статус",
      createNew: "Додати",
      empty:
        "Поки що у цій Торгівельній точці немає персоналу. Додайте першого...",
    },
    // user profile
    userProfile: {
      title: "Інформація працівника",
      phone: "Контактний телефон",
      role: "Роль",
      status: "Посада",
      login: "login",
      change: "Змінити",
      delete: "Видалити",
      // change user
      change_: {
        title: "Змінити інформацію працівника",
        titleCreate: "Створити працівника",
        name: "Ім'я:",
        surname: "Прізвище:",
        lastName: "По-батькові:",
        phone: "Номер телефону:",
        dolj: "Посада:",
        avatar: "Змінити аватар",
        addAvatar: "Додати аватар",
        roleTitle: "Роль у адмін панелі:",
        login: "Логін:",
        changePassFlag: "Змінити пароль?",
        pass1: "Задайте пароль:",
        pass2: "Повторіть пароль:",
        change: "Змінити",
        cancell: "Відміна",
        create: "Створити",
        avatarCreate: "Оберіть аватар...",
      },
      errors: {
        name: "Ім'я має складати від 2 до 30 символів",
        surname: "Прізвище має складати від 3 до 30 символів",
        lastName: "По-батькові має складати від 3 до 30 символів",
        phone: "Не вірний номер телефону",
        dolj: "Посада повинна скаладати від 2 до 50 символів",
        login: "Логін має складати від 4 до 25 символів",
        password: "Паролі мають співпадаюти і складати від 6 до 50 символів",
      },
    },

    // merchant
    merchant: {
      merchantRows: {
        personnelAccessRights: {
          title: "Права доступу / режим роботи",
          changingShopsNames: "Назначені магазини:",
          buttonSave: "Зберегти зміни",
        },
        merchantInfo: {
          name: "Назва Торгової мережі:",
          openingHours: {
            title: "Режим роботи:",
          },
          fieldNames: {
            city: "Місто:",
            street: "Вулиця:",
            buildingNumber: "Номер будинку:",
            postalCode: "Поштовий індекс:",
            notificationPhone: "телефон для sms:",
            sendSms: "Використовувати Sms для нотифікацій",
            sendEmail: "Використовувати email для нотифікацій",
            notificationEmail: "email для нотифікацій:",
            logoLink: "Логотип(посилання):",
          },
          titleNames: {
            address: "Адреса:",
            notifications: "Нотифікації:",
            contacts: "Контакти:",
            links: "Посилання:",
            logo: "Логотип:",
          },
          buttonNames: {
            saveChanges: "Зберегти зміни",
          },
          errors: {
            nameFieldError:
              "Ім'я Торгової точки має складати від 2 до 30 символів",
          },
        },
        shopInfo: {
          title: "Інформація торгівельної точки",
          openHours: {
            title: "Режим роботи:",
            checkboxText: "Робочий день",
            days: {
              d1: "Понеділок",
              d2: "Вівторок",
              d3: "Середа",
              d4: "Четвер",
              d5: "П'ятниця",
              d6: "Субота",
              d7: "Неділя",
            },
            buttons: {
              addToAllShops: "Додати для всіх магазинів",
              changeInSelectedShops: "Змінити у всіх магазинах",
            },
          },
          exclusionDays: {
            title: "Дні-винятки:",
            button_AddingNewDate1: "Додати нову дату",
            button_AddingNewDate2: "Додати",
            dateChecker_description: "Дата/час",
            radioInput_dayStatus1: "Робочий день",
            radioInput_dayStatus2: "Вихідний",
            alerts: {
              choosingAlert:
                "Додати виключення для всіх існуючих магазинів, або тільки для тих, які будуть створені в майбутньому?",
              choosingAlert_button1: "Для всіх",
              choosingAlert_button2: "Для нових",
              choosing1_confirm: "Виключення додано успішно",
            },
          },
        },
      },
      title: "Інформація торгової мережі",
      workingIn: {
        1: "Ви працюєте у магазині:",
        2: "за адресою: м. ",
        3: ", вул. ",
        4: ", буд. ",
      },
      chooseMerchant: "Оберіть фірму: ",
      choosedMerchant: "Фірма:",
      notChoosedMerchant: "Оберіть фірму щоб продовжити роботу",
      chooseFromList: "Оберіть зі списку",
      orders: {
        yes: "Так",
        toWork: {
          modal: {
            1: "Ви точно хочете взяти в опрацювання ",
            2: " замовлення?",
          },
          status: "Новий",
          label: "Взяти в роботу",
        },
        cancel: {
          modal: {
            1: "Ви точно хочете відмінити ",
            2: " замовлення?",
          },
          status: "Відмінений",
          label: "Відмінити",
        },
        pending: {
          modal: {
            1: "Ви точно хочете підтвердити ",
            2: " замовлення?",
          },
          status: "Оплачується",
          label: "Підтвердити",
        },
        inProcess: {
          modal: {
            1: "Ви точно хочете почати приготовлення ",
            2: " замовлення?",
          },
          status: "У процесі",
          label: "Почати приготування",
        },
        completed: {
          modal: {
            1: "замовлення номер ",
            2: " точно готово?",
          },
          status: "Готовий",
          label: "Прийняти готовність",
        },
        issue: {
          modal: {
            1: "замовлення номер ",
            2: " видане?",
          },
          status: "Видане",
          label: "Видача",
        },
        returned: {
          modal: {
            1: "замовлення номер ",
            2: " повернене клієнтом?",
          },
          status: "Повернення",
          label: "Повернення",
        },
        pend: {
          status: "Оплачений",
        },
        error: {
          status: "Помилка",
        },
      },
      ordersList: {
        sortingTypes: {
          byNumber: "За номером замовлення (за спаданням)",
          byTime: "За часом видачі замовлення"
        },
        name: "Замовлення ",
        previous: "Загрузити минулі ",
        next: "Загрузити наступні ",
        list: "список замовлень",
        header: {
          1: "Номер замовлення /",
          2: " статус",
          3: "Сума замовлення /",
          4: " Час замовлення",
        },
        footer: {
          1: "Сума замовлень на торговій точці:",
        },
      },
    },

    // personal cabinet
    perCab: {
      title: "Особистий кабінет",
      dolj: "Посада:",
      roleName: "Роль в адмін панелі:",
      changePass: {
        title: "Змінити пароль:",
        old: "Введіть старий пароль:",
        new: "Введіть новий пароль:",
        button: "Змінити пароль",
      },
      successChnage: "Ви успішно змінили пароль!",
    },

    // order details
    orDet: {
      title: "деталі замовлення",
      header: {
        1: "Найменування / ціна",
        2: "Кількість /",
        3: " вага",
        4: "Сума",
      },
      client: {
        title: "Клієнт: ",
        bazaniy_time: "Бажаний час отримання: ",
        comment: "Коментар: ",
      },
      footer: {
        1: "Всього:",
      },
    },

    // order detail item
    orDetItem: {
      100: "Ціна за ",
      1: "Ціна за 1 шт.:",
    },

    // categories
    cats: {
      title: "КАТЕГОРІЇ",
      create: {
        title1: "Створити",
        title2: "Cтворити нову підкатегорію в ",
        title3: "Створити нову Top-level категорію",
        name: "Введіть назву: ",
        url: "Введіть посилання на фото: ",
        photo: "Відображення фото: ",
        button: "Створити",
      },
      update: {
        title: "Змінити категорію ",
        name: "Змінити назву: ",
        url: "Змінити посилання на фото: ",
        photo: "Відображення фото: ",
        button: "Змінити",
      },
      table: {
        1: "Назва",
        2: "Фото",
        3: "Підкатегорії",
      },
      successCreate: "Ви успішно створили категорію ",
      successChange: "Ви успішно змінили категорію ",
      deleteMode: "Ви точно хочете видалити категорію: ",
      yes: "Так",
    },

    storage: {
      bm: {
        1: "сторінка товарів",
        2: "сторінка категорій",
      },
      shop: {
        1: "Оберіть заклад мережі: ",
        2: "Обраний заклад: ",
        4: "У цій фірмі закладів не знайдено.",
        d1: "Ви не обрали жодного закладу.",
        d2: "Оберіть заклад, щоб побачити асортимент.",
      },
    },

    prod: {
      title: "асортимент",
      button1: "Додати товар",
      search: {
        name: "Назва",
        cat: "Категорія",
        search: "Пошук",
        cancel: "Відміна",
        cancel2: "Відмінити",
        selectTitle: "Натисніть на товар щоб обрати його",
        add: "Додати",
      },
      table: {
        1: "Ім'я",
        2: "Фото",
        3: "Категорія",
        4: "Ціна ₴",
        5: "Кількість",
        6: "К.",
        7: "Опції",
      },
      successCreate: "Ви успішно створили товар ",
      successChange: "Ви успішно змінили товар ",
      deleteMode: "Ви точно хочете видалити товар: ",
      yes: "Так",
      no: "Ні",
    },
    prodErrors: {
      0: "Ім'я повинно бути від 2 до 50 символів",
      1: "Не обрана категорія",
      2: "Посилання на фото не повинно перевищувати 2000 символів",
      3: "Не обрані одиниці виміру",
      4: "Не вказана кількість товарів",
      5: "Не вказана ціна",
    },
    createProd: {
      productType: 'Тип товару',
      productTypes: {
        weight: 'ваговий',
        piece: 'штучний'
      },
      title: "додати товар",
      title2: "змінити товар",
      closeLabel: "Закрити",
      name: "Назва: ",
      descr: "Опис: ",
      cat: "Категорія: ",
      url: "Фото: ",
      type: "Одиниці виміру: ",
      inStock: 'В наявності',
      typeList: {
        1: "вага",
        2: "шт. (мл.)",
        3: "шт. (г.)",
      },
      measUnits: {
        ml: "мілілітрів",
        gr: "грамів",
        piece: "штук"
      },
      sizeList: {
        1: "розмір (мл.): ",
        2: "розмір (г.): ",
      },
      weightAndVolume: "Вага / Об'єм",
      price: "Ціна, ₴ ",
      count: {
        1: "Вкажіть скільки є зараз товарів у наявності",
        2: "шт.",
        3: "г.",
      },
      button1: "Додати",
      button2: "Відмінити",
      button3: "Змінити",
      placeholders: {
        enterName: 'Введіть назву',
        enterLink: 'Введіть посилання на фото'
      }
    },

    import: {
      loading: "Почекайте поки файл імпортується",
      error: "Щось пішло не так...",
      success: "Імпорт пройшов успішно!",
      title: "Імпорт",
    },
  },
};
