export const inputTypes = {
  PHONE_NUMBER: "PHONE_NUMBER",
  PASSWORD: "password"
};

export const mediaPicturesExtensions = ["image/jpeg", "video/mp4", "image/png"];

export const methodTypes = {
  CREATE: "CREATE",
  UPDATE: "UPDATE"
};

export const authUrls = {
  SAMO_AUTH: "auth",
  INSTRUCTION: "instruction",
  REG: "joinposter-registration",
  REG_CONFIRM: "joinposter-registration-confirm",
  IFRAME: "joinposter-iframe"
};

/* Order statuses */
export const orderStatuses = {
  STATE_ERROR: { value: "-1", text: "Помилка" },
  STATE_ISSUED: { value: "0", text: "Виконано" },
  STATE_CANCELLED: { value: "1", text: "Скасовано" },
  STATE_CONFIRMED: { value: "2", text: "Оброблюється" },
  STATE_PAID: { value: "3", text: "Оплачене" },
  STATE_PENDING: { value: "4", text: "Очікує оплату" },
  STATE_IN_PROCESS: { value: "5", text: "Готується" },
  STATE_COMPLETED: { value: "6", text: "Готове" },
  STATE_RETURNED: { value: "7", text: "Повернено" },
  STATE_CANCELLED_WITHOUT_REFUND: { value: "8", text: "Відмінено" },
  STATE_CANCELLING: { value: "9", text: "-" }
};