import { authUrls } from "../dataUI/common/commonConsts";

export const joinposterId = "3176";
export const application_secret = "f7ec4402bbd7e149ee465458e88a505f";
// main - https://utake.ukrpay.net/webadmin/
// test - http://192.168.65.156:9710
// test-mobile - http://192.168.65.71:4431
//export const backendUrl = "http://192.168.65.71:4431";
//export const backendUrl = "https://adminsamo.com.ua/webadmin/";


//// backendUrl & posterUrl & joinPosterConfirmUrl
// test
//export const backendUrl = "http://192.168.65.155:9710";
export const backendUrl = "https://samoapi.ukrpay.net";
export const posterUrl = "https://poster.ukrpay.net";
export const joinPosterConfirmUrl = `https://adminsamo.com.ua/${authUrls.REG_CONFIRM}`
// prod
/*export const backendUrl = "https://philippok.ukrpay.net/webadmin/";
export const posterUrl = ???;
export const joinPosterConfirmUrl = `https://adminsamo.com.ua/${authUrls.REG_CONFIRM}`;*/
// dev
/*export const backendUrl = "http://192.168.65.156:9714";
export const posterUrl = "https://moccasin-relaxed-weekly.ngrok-free.app";
export const joinPosterConfirmUrl = `http://localhost:3000/${authUrls.REG_CONFIRM}`;*/


