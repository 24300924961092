import $api from "../../../api/http";

class Products {
  constructor(merchantId, shopId) {
    this.merchantId = merchantId;
    this.shopId = shopId;
  }

  getAllProd(limit, offset, searchName, category) {
    const { merchantId, shopId } = this;
    const catParam = category ? `&categoryId=${category}` : ''
    const titleParam = searchName ? `&title=${searchName}` : ''
    return $api.get(
      `/Merchants/${merchantId}/shops/${shopId}/products?limit=${limit}&offset=${offset}${titleParam}${catParam}`,
    );
  }

  getOneById(id) {
    const { merchantId, shopId } = this;
    return $api.get(`/Merchants/${merchantId}/shops/${shopId}/products/${id}`);
  }

  search(name, catId, limit, offset) {
    const { merchantId, shopId } = this;
    const catParam = catId ? `&categoryId=${catId}` : ''
    const titleParam = name ? `&title=${name}` : ''
    const route = `/Merchants/${merchantId}/shops/${shopId}/products?limit=${limit}&offset=${offset}${titleParam}${catParam}`
    return $api.get(route);
  }

  onCreateProd(body) {
    const { merchantId, shopId } = this;
    return $api.post(`/Merchants/${merchantId}/shops/${shopId}/products`, body);
  }

  onEdit(id, body) {
    const { merchantId, shopId } = this;
    const route = `/Merchants/${merchantId}/shops/${shopId}/products/${id}`;
    return $api.put(route, body);
  }

  deleteById(id) {
    const { merchantId, shopId } = this;
    return $api.delete(
      `/Merchants/${merchantId}/shops/${shopId}/products/${id}`,
    );
  }

  onImportProducts(file) {
    const { merchantId, shopId } = this;
    console.log("file = ", file);
    const route = `/Merchants/${merchantId}/shops/${shopId}/uploadproductcatalog`;
    return $api.post(route, file, {
      headers: {
        "Content-Type": "application/xml",
        "File-name": file.name,
      },
    });
  }
}

export default Products;
