import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ClearIcon from "@material-ui/icons/Clear";

import ConsistItem from "./ConsistItem/ConsistItem";
import Spinner from "../../components/UI/Spinner/Spinner";
import ProductsModal from "../../components/UI/ProductsModal/ProductsModal";
import ProductsForModal from "../ProductsForModal/ProductsForModal";

// components
import ClientInfo from "./components/ClientInfo/ClientInfo";

import basketImg from "../../assets/images/components/ConsistList/basket.svg";
import done from "../../assets/images/components/ConsistList/done.svg";
import add from "../../assets/images/components/ConsistList/add.svg";

import "./consistList.scss";
import { orderStatuses } from "../../dataUI/common/commonConsts";

const genStatusesList = (currentStatus) => {
  const {
    STATE_CONFIRMED,
    STATE_PENDING,
    STATE_CANCELLED,
    STATE_PAID,
    STATE_COMPLETED,
    STATE_IN_PROCESS,
    STATE_ISSUED,
    STATE_RETURNED
  } = orderStatuses;

  let statusesList;
  switch (String(currentStatus)) {
    case STATE_CONFIRMED.value : {
      statusesList = [STATE_PENDING, STATE_CANCELLED];
      break;
    }
    case STATE_PENDING.value : {
      statusesList = [STATE_PAID, STATE_COMPLETED, STATE_CANCELLED];
      break;
    }
    case STATE_PAID.value : {
      statusesList = [STATE_IN_PROCESS, STATE_COMPLETED, STATE_CANCELLED];
      break;
    }
    case STATE_IN_PROCESS.value : {
      statusesList = [STATE_COMPLETED, STATE_CANCELLED];
      break;
    }
    case STATE_COMPLETED.value : {
      statusesList = [ STATE_ISSUED, STATE_CANCELLED];
      break;
    }
    case STATE_ISSUED.value : {
      statusesList = [STATE_RETURNED, STATE_CANCELLED];
      break;
    }
    case STATE_RETURNED.value : {
      statusesList = [STATE_CANCELLED];
      break;
    }
    default: {
      statusesList = [];
    }
  }
  return statusesList;
};

const ConsistList = (props) => {
  const [selectProd, setSelectProd] = useState(false);

  const [products, setProducts] = useState(
    props.order.order.shoppingCart.products
  );
  const [changedStatus, setChangedStatus] = useState("");
  const [availableStatuses, setAvailableStatuses] = useState([]);

  const { t } = useTranslation();

  const info = JSON.parse(localStorage.getItem("info"));
  const userId = info.id;
  const merchantId = info.merchantId;
  const shopId = info.shopId;
  const roleId = info.roleId;
  const { onDenyOrder } = props;

  useEffect(() => {
    setChangedStatus("");
    setProducts(props.order.order.shoppingCart.products);

    /* set select statuses list */
    const statusesList = genStatusesList(props.order.order.state);
    setAvailableStatuses(statusesList);
  }, [props.order]);

  const onStatusChange = (status) => {
    setChangedStatus(status);
  };


  const onChangeBulkProduct = (id, e) => {
    let newProducts = [...products];
    const oldProductQ = [...props.order.order.shoppingCart.products].filter(
      (el) => el.pd.id === id
    )[0]
      ? [...props.order.order.shoppingCart.products].filter(
        (el) => el.pd.id === id
      )[0].q
      : 0;

    let index = newProducts.indexOf(
      newProducts.filter((el) => el.pd.id === id)[0]
    );
    newProducts[index] = {
      ...newProducts[index],
      q:
        +e.target.value >= 1
          ? +e.target.value > newProducts[index].pd.q + oldProductQ
            ? newProducts[index].pd.q + oldProductQ
            : +e.target.value
          : 0
    };
    setProducts(newProducts);
  };
  const onChangeNotBulkProduct = (id, count) => {
    let newProducts = [...products];
    const oldProductQ = [...props.order.order.shoppingCart.products].filter(
      (el) => el.pd.id === id
    )[0].q;

    let index = newProducts.indexOf(
      newProducts.filter((el) => el.pd.id === id)[0]
    );
    newProducts[index] = {
      ...newProducts[index],
      q:
        +count >= 1
          ? +count > newProducts[index].pd.q + oldProductQ
            ? newProducts[index].pd.q + oldProductQ
            : +count
          : 0
    };
    setProducts(newProducts);
  };


  const orderSum = +products
    .reduce((acc1, val1) => {
      if (val1.pd.bl) {
        return (
          acc1 +
          (val1.q / (val1.pd.w === 0 ? val1.pd.v : val1.pd.w)) * val1.pd.p
        );
      } else {
        return acc1 + val1.q * val1.pd.p;
      }
    }, 0)
    .toFixed(2);

  const onAddProduct = (prod) => {
    setSelectProd(false);
    const newProducts = [...products];
    if (newProducts.filter((el) => el.pd.id === prod.id)[0]) {
      return;
    }
    newProducts.unshift({
      pd: prod,
      q: prod.bl ? (prod.q < 100 ? prod.q : 100) : 1
    });
    setProducts(newProducts);
  };

  const onDeleteProd = (id) => {
    const newProducts = [...products].filter((el) => el.pd.id !== id);
    setProducts(newProducts);
  };


  return (
    <div className="consist-list-block">
      <ProductsModal show={selectProd} onClose={() => setSelectProd(false)}>
        <ProductsForModal
          {...props}
          merchantId={roleId !== -1 ? merchantId : props.selectedMerchant.id}
          shopId={![-1, 1].includes(roleId) ? shopId : props.selectedShop.id}
          modal={true}
          onAdd={(el) => onAddProduct(el)}
          onClose={() => setSelectProd(false)}
        />
      </ProductsModal>
      <div className="consist-list-block__header">
        <div className="orders-list-block__header_title">
          <img src={basketImg} alt="orders" />
          <h2>{`${t("orDet.title")}  №${props.order.order.id}`}</h2>
        </div>
        {props.order.order.state === 2 &&
          props.order.account &&
          props.order.account.accountId === userId && (
            <button
              onClick={() => onDenyOrder(props.order.order.id)}
              className="deny-order"
            >
              {t("Відмовитись від замовлення")}
            </button>
          )}
        <div className="consist-list-block__header_subtitle">
          <p>{t("orDet.header.1")}</p>
          <div className="consist-list-block__header_subtitle_right">
            <p className="mr">
              {t("orDet.header.2")}
              <br />
              {t("orDet.header.3")}
            </p>
            <p>{t("orDet.header.4")}</p>
          </div>
        </div>
      </div>

      <div className="consist-list-block__body">
        <div className="consist-list-block__body_scrolled">
          {!props.orderLoading &&
            products.map((el, index) => (
              <ConsistItem
                {...props}
                good={el}
                key={index}
                locked={
                  !(
                    props.order.account &&
                    props.order.account.accountId === userId &&
                    props.order.order.state === 2
                  )
                }
                onDeleteProd={onDeleteProd}
                onChangeBulkProduct={onChangeBulkProduct}
                onChangeNotBulkProduct={onChangeNotBulkProduct}
              />
            ))}
          {props.orderLoading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "200px"
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
        <ClientInfo order={props.order} />
        <div className="scrollable-shadow" />
      </div>

      {!props.orderLoading && (
        <>
          <div className="consist-list-block__footer">
            {props.order.account &&
              (props.order.account.accountId === userId ||
                [-1, 1].includes(roleId)) && (
                <>
                  {props.order.order.state === 2 && (
                    <>
                      <button
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => setSelectProd(true)}
                      >
                        <img src={add} alt="" />
                        <p
                          style={{
                            color: "#000",
                            marginLeft: "8px",
                            width: "65px",
                            textAlign: "left"
                          }}
                        >
                          {t("prod.button1")}
                        </p>
                      </button>
                      {products.length !== 0 && (
                        <button
                          onClick={() =>
                            props.onSendToPending({
                              ...props.order.order,
                              shoppingCart: {
                                ...props.order.order.shoppingCart,
                                products: products
                              }
                            })
                          }
                        >
                          <img src={done} alt="done" />
                          <span>{t("merchant.orders.pending.label")}</span>
                        </button>
                      )}
                      <button
                        onClick={() => props.cancelOrder(props.order.order.id)}
                        style={{
                          backgroundColor: "#ad3d3d",
                          color: "#fbe2e2",
                          padding: "8px 10px",
                          marginLeft: "15px"
                        }}
                      >
                        {window.innerWidth > 1250 && (
                          <span style={{ marginLeft: "0" }}>
                          {t("merchant.orders.cancel.label")}
                        </span>
                        )}
                        {window.innerWidth <= 1250 && (
                          <ClearIcon style={{ width: "17px", height: "17px" }} />
                        )}
                      </button>
                    </>
                  )}
                  {props.order.order.state === 3 && (
                    <button
                      onClick={() =>
                        props.onSendToProcessIn(props.order.order.id)
                      }
                      style={{ padding: "8px 10px" }}
                    >
                    <span style={{ marginLeft: "0" }}>
                      {t("merchant.orders.inProcess.label")}
                    </span>
                    </button>
                  )}
                  {props.order.order.state === 5 && (
                    <button
                      onClick={() => props.onCompleteOrder(props.order.order.id)}
                      style={{ padding: "8px 10px" }}
                    >
                    <span style={{ marginLeft: "0" }}>
                      {t("merchant.orders.completed.label")}
                    </span>
                    </button>
                  )}
                  {props.order.order.state === 6 && (
                    <button
                      onClick={() => props.onIssueOrder(props.order.order.id)}
                      style={{ padding: "8px 10px" }}
                    >
                    <span style={{ marginLeft: "0" }}>
                      {t("merchant.orders.issue.label")}
                    </span>
                    </button>
                  )}
                  {props.order.order.state === 0 && (
                    <button
                      onClick={() => props.onReturnOrder(props.order.order.id)}
                      style={{
                        padding: "8px 10px",
                        backgroundColor: "#ad3d3d",
                        color: "#fbe2e2"
                      }}
                    >
                    <span style={{ marginLeft: "0" }}>
                      {t("merchant.orders.returned.label")}
                    </span>
                    </button>
                  )}
                </>
              )}
            {!props.order.account && props.order.order.state === 2 && (
              <button
                onClick={() => props.addOrderToWork(props.order.order.id)}
                style={{ padding: "8px 10px" }}
              >
              <span style={{ marginLeft: "0" }}>
                {t("merchant.orders.toWork.label")}
              </span>
              </button>
            )}
            {/* Price */}
            <div className="consist-list-block__footer_right">
              <p>{t("orDet.footer.1")}</p>
              <span>{orderSum} ₴</span>
            </div>
          </div>

          <div className="consist-list-block__footer changeStatusBox" style={{ justifyContent: "flex-start" }}>
            <select value={changedStatus} name="select" className="changeStatus_select"
                    onChange={(e) => onStatusChange(e.target.value)}>
              <option value="" disabled>Змінити статус</option>

              {availableStatuses.map((status) => <option key={status.value} value={status.value}>{status.text}</option>)}
            </select>

            {changedStatus && <button
              onClick={() => props.changeOrderStatus(props.order.order.id, changedStatus)}
              className="changeStatus_button"
            >
              <span style={{ marginLeft: "0" }}>
                Зберегти статус
              </span>
            </button>}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedMerchant: state.admin.selectedMerchant
});

export default connect(mapStateToProps)(ConsistList);
